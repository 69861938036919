import React from "react";
import { Link } from "react-router-dom";
import "./ServiceCard.css";
import UpArrow from "../../Assets/ServicesImages/arrow_right.svg"
import CardIcon from "../../Assets/ServicesImages/service-inner1.svg"
import Quito3 from "../../Assets/quito3.png"


function ServiceCard(){



    return(
        <React.Fragment>
            <div class="service-inner-section-area sp1">
                <div class="container">
                    <div class="row">
                    <div class="col-lg-4 col-md-6">
                                    <div class="service2-author-box">
                                        <div class="tax-icon-img">
                                            <img src={CardIcon} alt=""/>
                                        </div>
                                        <div class="tax-content">
                                            <a href="service-single.html">Drug Offences</a>
                                            <p>I have extensive experience defending clients charged under the Misuse of Drugs Act, navigating the intricacies of drug-related laws and potential penalties.</p>
                                        </div>
                                        <a href="service-single.html" class="learn-more">Read More<i class="fa-regular fa-arrow-right"></i></a>
                                        <div class="arrow-service">
                                        <span><a href="service-single.html"><img src={UpArrow} alt=""/></a></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="service2-author-box">
                                        <div class="tax-icon-img">
                                            <img src={CardIcon} alt=""/>
                                        </div>
                                        <div class="tax-content">
                                            <a href="service-single.html">White-Collar Crimes</a>
                                            <p>My practice includes defending against allegations of fraud, corruption, and embezzlement. I work diligently to protect my clients’ interests in cases involving complex financial transactions.</p>
                                        </div>
                                        <a href="service-single.html" class="learn-more">Read More<i class="fa-regular fa-arrow-right"></i></a>
                                        <div class="arrow-service">
                                    <span><a href="service-single.html"><img src={UpArrow} alt=""/></a></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="service2-author-box">
                                        <div class="tax-icon-img">
                                            <img src={CardIcon} alt=""/>
                                        </div>
                                        <div class="tax-content">
                                            <a href="service-single.html">Violent Crimes</a>
                                            <p>I represent clients accused of serious offences, including assault and homicide. Each case is approached with a tailored strategy aimed at achieving the best possible outcome.</p>
                                        </div>
                                        <a href="service-single.html" class="learn-more">Read More<i class="fa-regular fa-arrow-right"></i></a>
                                        <div class="arrow-service">
                                    <span><a href="service-single.html"><img src={UpArrow} alt=""/></a></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="service2-author-box">
                                        <div class="tax-icon-img">
                                            <img src={CardIcon} alt=""/>
                                        </div>
                                        <div class="tax-content">
                                            <a href="service-single.html">Sexual Offences</a>
                                            <p>Handling sensitive cases involving sexual allegations requires a nuanced understanding of both legal and emotional aspects. I ensure that my clients receive compassionate yet robust representation.</p>
                                        </div>
                                        <a href="service-single.html" class="learn-more">Read More<i class="fa-regular fa-arrow-right"></i></a>
                                        <div class="arrow-service">
                                    <span><a href="service-single.html"><img src={UpArrow} alt=""/></a></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="service2-author-box">
                                        <div class="tax-icon-img">
                                            <img src={CardIcon} alt=""/>
                                        </div>
                                        <div class="tax-content">
                                            <a href="service-single.html">Violent Crimes</a>
                                            <p>I represent clients accused of serious offences, including assault and homicide. Each case is approached with a tailored strategy aimed at achieving the best possible outcome</p>
                                        </div>
                                        <a href="service-single.html" class="learn-more">Read More<i class="fa-regular fa-arrow-right"></i></a>
                                        <div class="arrow-service">
                                    <span><a href="service-single.html"><img src={UpArrow} alt=""/></a></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="service2-author-box">
                                        <div class="tax-icon-img">
                                            <img src={CardIcon} alt=""/>
                                        </div>
                                        <div class="tax-content">
                                            <a href="service-single.html">White-Collar Crimes</a>
                                            <p>My practice includes defending against allegations of fraud, corruption, and embezzlement. I work diligently to protect my clients’ interests in cases involving complex financial transactions.</p>
                                        </div>
                                        <a href="service-single.html" class="learn-more">Read More<i class="fa-regular fa-arrow-right"></i></a>
                                        <div class="arrow-service">
                                    <span><a href="service-single.html"><img src={UpArrow} alt=""/></a></span>
                                        </div>
                                    </div>
                                </div>     
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ServiceCard