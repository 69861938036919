import React from "react";
import { Link } from "react-router-dom";
import Quito3 from "../../Assets/quito3.png";
import "./Blog.css";


export default function Work() {
    return (
    <React.Fragment>
        <div class="blog5-section-area sp1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 m-auto">
                        <div class="blog5-header text-center">
                        <span className="sectionSub_title" data-aos="fade-up" data-aos-duration="600">Our Blogs</span>
                        <h2 className="section_title" data-aos="fade-up" data-aos-duration="800">Our Latest Blog Post<img src={Quito3} alt=""/></h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6" data-aos="fade-right" data-aos-duration="800">
                        <div class="blog5-boxarea">
                            <div class="blog5-imgeas">
                            <img src="https://placehold.co/408x287" alt=""/>
                            </div>
                            <div class="blog5-content-area">
                            <div class="tags-area">
                            <Link to="#">#Legal Assessment</Link>
                            </div>
                            <Link to="blog-single.html">Navigating Co-Parenting Law Challenges After Divorce</Link>
                            <p>Child custody is a central concern in many to divorces, We'll explore the different Lawyers.</p>
                            <Link to="blog-single.html" class="readmore">Read More <i class="fa-regular fa-arrow-right"></i></Link>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-right" data-aos-duration="1000">
                        <div class="blog5-boxarea">
                            <div class="blog5-imgeas">
                            <img src="https://placehold.co/408x287" alt=""/>
                            </div>
                            <div class="blog5-content-area">
                            <div class="tags-area">
                            <Link to="#">#Property Division</Link>
                            </div>
                            <Link to="blog-single.html">The Different Types of Divorce: Which One Is Right for You?"</Link>
                            <p>Child custody is a central concern in many to divorces, We'll explore the different Lawyers.</p>
                            <Link to="blog-single.html" class="readmore">Read More <i class="fa-regular fa-arrow-right"></i></Link>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-right" data-aos-duration="1200">
                        <div class="blog5-boxarea">
                            <div class="blog5-imgeas">
                            <img src="https://placehold.co/408x287" alt=""/>
                            </div>
                            <div class="blog5-content-area">
                            <div class="tags-area">
                            <Link to="#">#Child Custody & Support</Link>
                            </div>
                            <Link to="blog-single.html">Divorce and Child Custody: The Putting Children First</Link>
                            <p>Child custody is a central concern in many to divorces, We'll explore the different Lawyers.</p>
                            <Link to="blog-single.html" class="readmore">Read More <i class="fa-regular fa-arrow-right"></i></Link>
                            </div>
                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
        )
}