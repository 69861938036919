import React from "react";
import { Link } from "react-router-dom";
import "./WhyChoose.css";
import Quito3 from "../../Assets/quito3.png"
import Accordion from 'react-bootstrap/Accordion';
function WhyChoose(){



    return(

        
        <section className="WhyChoose sp1">
            
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="img-content">
                            <div className="img-box" data-aos="fade-up" data-aos-duration="900">
                                <img src="https://placehold.co/520x550" alt="" />
                            </div>
                            <h5 className="whyChoose-imgTitle">We Have The <span>5</span> Years Experience</h5>
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div class="about5-content-area">
                             <span className="sectionSub_title" data-aos="fade-up" data-aos-duration="600">Why Choose Us</span>
                             <h2 className="section_title" data-aos="fade-up" data-aos-duration="800">The Advantage of Choosing My<img className="title-img" src={Quito3} alt=""/> Services</h2>
                             <p className="common-para">
                             As a criminal defence lawyer in Singapore, I offer legal representation for individuals facing charges such as drug offences, white-collar crimes, and violent crimes. Understanding the challenges criminal cases bring, I am dedicated to protecting my clients' rights throughout the legal process.
                             </p>
                             <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Tailored Solutions</Accordion.Header>
                                    <Accordion.Body className="common-para">
                                        I recognize that every business is unique. I take the time to understand my clients' specific needs and challenges, providing customized legal strategies that align with their business objectives.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Proactive Legal Support</Accordion.Header>
                                    <Accordion.Body className="common-para">
                                        By engaging my services early on, SMEs can mitigate risks associated with legal issues. I offer ongoing support to help businesses navigate potential challenges before they escalate into disputes.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Proactive Legal Support</Accordion.Header>
                                    <Accordion.Body className="common-para">
                                        By engaging my services early on, SMEs can mitigate risks associated with legal issues. I offer ongoing support to help businesses navigate potential challenges before they escalate into disputes.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default WhyChoose