import React from "react";
import { Link } from "react-router-dom";
import LawIcon from "../../Assets/Work-images/law-img4.svg"
import CaseImage1 from "../../Assets/home-page-images/casestudy-img1.png"
import CaseImage2 from "../../Assets/home-page-images/casestudy-img2.png"
import Quito3 from "../../Assets/quito3.png";
import "./CaseStudy.css";


export default function CaseStudies() {
    return (
    <React.Fragment>
        <div class="casestudy5-section-area sp1">
            <div class="container">
                    <div class="row">
                        <div class="col-lg-5 m-auto">
                            <div class="casestudy5-header text-center">
                                <span className="sectionSub_title" data-aos="fade-up" data-aos-duration="600">Case Study</span>
                                <h2 className="section_title" data-aos="fade-up" data-aos-duration="800">Featured Case Studies<img src={Quito3} alt=""/></h2>
                            </div>
                        </div>
                    </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6" data-aos="zoom-out" data-aos-duration="800">
                        <div class="casestudy5-all-area">
                            <div class="casestudy5-images">
                                <img src={CaseImage1} alt=""/>
                            </div>
                            <div class="casestudy5-content-area">
                                <Link to="service-single.html"> A Successful Custody Resolution – How we are Secured the best Interests of the Children.</Link>
                                <p>We're not just lawyers; we're your partners in starting a new law chapter in your life. We offer post-divorce support & resources.</p>
                                <Link to="service-single.html" class="learnmore">Read More <i class="fa-regular fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6" data-aos="zoom-out" data-aos-duration="1000">
                        <div class="casestudy5-all-area">
                            <div class="casestudy5-images">
                                <img src={CaseImage2} alt=""/>
                            </div>
                            <div class="casestudy5-content-area">
                                <Link to="service-single.html"> A Successful Custody Resolution – How we are Secured the best Interests of the Children.</Link>
                                <p>We're not just lawyers; we're your partners in starting a new law chapter in your life. We offer post-divorce support & resources.</p>
                                <Link to="service-single.html" class="learnmore">Read More <i class="fa-regular fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" data-aos="fade-up" data-aos-duration="1200">
                            <div class="div text-center">
                                <Link to="service1.html" class="welcome5-btn">View More Services <i class="fa-regular fa-arrow-right"></i></Link>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
        )
}