import React from "react";
import { Link } from "react-router-dom";
import "./Service.css";
import Header from "../../Components/Header"
import InnerBanner from "../../Components/Inner-Page-Banner";
import ServiceCard from "../../Components/ServiceCard"
import ServiceCTA from "../../Components/ServiceCTA"
import ServiceAbout from "../../Components/Service-About";
import WhyChoose from "../../Components/WhyChoose";
import Testimonial from "../../Components/Testimonial/index.jsx"
import Footer from "../../Components/Footer";


export default function Service(){
    return(
        <React.Fragment>
            <Header/>
            <InnerBanner/>
            <ServiceAbout/>
            <ServiceCard/>
            <WhyChoose/>
            <ServiceCTA/>
            <Testimonial/>
            <Footer/>
        </React.Fragment>
    )
}