import React from "react";
import { Link } from "react-router-dom";
import LawIcon from "../../Assets/Work-images/law-img4.svg"
import Quito3 from "../../Assets/quito3.png";
import "./Work.css";


export default function Work() {
    return (
    <React.Fragment>
        <div class="work5-section-area sp1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 m-auto">
                    <div class="work4-header text-center">
                        <span className="sectionSub_title" data-aos="fade-up" data-aos-duration="600">How It Works</span>
                        <h2 className="section_title" data-aos="fade-up" data-aos-duration="800">Support in Every Case <img src={Quito3} alt=""/></h2>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4" data-aos="fade-up" data-aos-duration="800">
                    <div class="work4-boxarea">
                        <div class="work4-autor-area">
                        <div class="work4-icons">
                            <img src={LawIcon} alt=""/>
                        </div>
                        <h3>01</h3>
                        </div>
                        <div class="work4-content-area">
                        <a href="service-single.html">Investigation</a>
                        <p>Tax authorities, such as the Internal Revenue to Service (IRS) in the United States.</p>
                        </div>
                    </div>
                    </div>


                    <div class="col-lg-4" data-aos="fade-up" data-aos-duration="1000">
                    <div class="work4-boxarea">
                        <div class="work4-autor-area">
                        <div class="work4-icons">
                            <img src={LawIcon} alt=""/>
                        </div>
                        <h3>02</h3>
                        </div>
                        <div class="work4-content-area">
                        <a href="service-single.html">Legal Representation</a>
                        <p>Tax authorities, such as the Internal Revenue to Service (IRS) in the United States.</p>
                        </div>
                    </div>
                    </div>

                    <div class="col-lg-4" data-aos="fade-up" data-aos-duration="1200">
                    <div class="work4-boxarea">
                        <div class="work4-autor-area">
                        <div class="work4-icons">
                            <img src={LawIcon} alt=""/>
                        </div>
                        <h3>03</h3>
                        </div>
                        <div class="work4-content-area">
                        <a href="service-single.html">Compliance & Solution</a>
                        <p>Tax authorities, such as the Internal Revenue to Service (IRS) in the United States.</p>
                        </div>
                    </div>
                    </div>
                </div>
             </div>
        </div>   
    </React.Fragment>
        )
}