import React from "react";
import "./preloader.css";
import Preloaderimg from "../../Assets/preloader.svg";


export default function Preloader(){
    return(
        <React.Fragment>
            <div className="se-pre-con preloader5"> 
                <div className="outer">
                    <div className="middle">
                        <div className="inner">
                            <div className="Preloader-icon">
                                <img src={Preloaderimg} alt="Preloader" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </React.Fragment>
    )
}