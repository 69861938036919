import React from "react";
import { Link } from "react-router-dom";
import "./header.css";
import MainLogo from "../../Assets/mainlogo.png"
import { FaAngleDown } from "react-icons/fa6";


export default function Header(){
    return(
        <React.Fragment>
            <header className="header about-bg d-none d-lg-block">
                <div className="header-area header homepage5 header-sticky" id="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="header-elements">
                                    <div className="site-logo">
                                        <Link to="index.html"><img src={MainLogo} alt="" /></Link>
                                    </div>
                                    <div className="main-menu-ex homepage5">
                                        <ul>
                                            <li><Link to="/" className=" mainhome">Home</Link></li>
                                            <li><Link to="about.html">About Us</Link></li>
                                            <li><Link to="/Service">Practise areas /services <span><FaAngleDown/></span></Link>
                                                <ul className="dropdown-padding">
                                                    <li><Link to="service1.html" >Service One</Link></li>
                                                    <li><Link to="service2.html" >Service Two</Link></li>
                                                    <li><Link to="service-left.html" >Service Left Side</Link></li>
                                                    <li><Link to="service-right.html" >Service Right Side </Link></li>
                                                    <li><Link to="service-single.html" >Service Single </Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="#" >Blogs /Media</Link></li>
                                            <li><Link to="#" >Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}