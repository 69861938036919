import React from "react";
import { Link } from "react-router-dom";
import Quito3 from "../../Assets/quito3.png";
import CallIcon from "../../Assets/home-page-images/call-img3.svg"
import EmailIcon from "../../Assets/home-page-images/email-img1.svg"
import "./HomeCTA.css";


export default function HomeCta() {
    return (
    <React.Fragment>
        <div class="cta5-section-area sp4" >
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <div class="cta5-header-area">
                         <h2 className="section_title" data-aos="fade-up" data-aos-duration="800">Start Your Journey to <img src={Quito3} alt=""/> <br/> a Fresh Beginning</h2>
                        </div>
                    </div>
                    <div class="col-lg-1"></div>
                <div class="col-lg-6">
                    <div class="cta-contact-area">
                        <div class="row">
                            <div class="col-lg-6 col-md-6" data-aos="zoom-in" data-aos-duration="800">
                                <div class="cta5-author-area">
                                <div class="cta5-icons">
                                    <img src={CallIcon} alt=""/>
                                </div>
                                <div class="cta5-content">
                                    <p>Call Us 24/7</p>
                                    <Link to="tel:+1234567890">+123 456 7890</Link>
                                </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6" data-aos="zoom-in" data-aos-duration="1000">
                                <div class="cta5-author-area">
                                <div class="cta5-icons">
                                    <img src={EmailIcon} alt=""/>
                                </div>
                                <div class="cta5-content">
                                    <p>Mail Us Anytime</p>
                                    <Link to="mailto:Lawsy@gmail.com">Lawsy@gmail.com</Link>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </React.Fragment>
        )
}