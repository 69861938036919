import React from "react";
import { Link } from "react-router-dom";
import "./InnerBanner.css";
import Quito3 from "../../Assets/quito3.png"
import { MdOutlineKeyboardArrowRight } from "react-icons/md";


function InnerBanner(){



    return(
        <section className="inner-banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                            <div className="welcome5-textarea">
                                <h1 data-aos="fade-up" data-aos-duration="800">Practise Areas<img src={Quito3} alt=""/></h1>
                            </div>
                            <div className="innerBanner-BreadCrum">
                                <ul>
                                    <li><Link to="/">Home <MdOutlineKeyboardArrowRight /></Link></li>
                                    <li><Link to="/"><span>Practive Areas</span></Link></li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default InnerBanner