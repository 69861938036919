export const SliderData = [
    {
        id: '1',
        service_img: 'https://placehold.co/412x412',
        service_heading: 'Divorce Consultation',
        service_content: 'Our mission at Defend Right Landing to Pages is to provide comprehensive and reliable digital solutions for criminal.',
        service_link: '#'
    },
    {
        id: '2',
        service_img: 'https://placehold.co/412x412',
        service_heading: 'Child Custody & Support',
        service_content: 'Our mission at Defend Right Landing to Pages is to provide comprehensive and reliable digital solutions for criminal.',
        service_link: '#'
    },
    {
        id: '3',
        service_img: 'https://placehold.co/412x412',
        service_heading: 'Divorce Consultation',
        service_content: 'Our mission at Defend Right Landing to Pages is to provide comprehensive and reliable digital solutions for criminal.',
        service_link: '#'
    },
    {
        id: '4',
        service_img: 'https://placehold.co/412x412',
        service_heading: 'Child Custody & Support',
        service_content: 'Our mission at Defend Right Landing to Pages is to provide comprehensive and reliable digital solutions for criminal.',
        service_link: '#'
    }
]