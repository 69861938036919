import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import MainLogo from "../../Assets/mainlogo.png"


export default function Footer() {
    return (
        <React.Fragment>
        <div className="footer5-section-area sp5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="footer-all-section-area sp5">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-last-section">
                                        <div className="footer-imgage">
                                            <img src={MainLogo} alt=""/>
                                        </div>
                                        <div className="footer-text-area">
                                            <p>Include any additional information that may be relevant or helpful for visitors, such as FAQs, pricing options.</p>
                                            <div className="social-list-area">
                                                <ul>
                                                    <li><Link to="#"><i className="fa-brands fa-facebook-f"></i></Link></li>
                                                    <li><Link to="#"><i className="fa-brands fa-x-twitter"></i></Link></li>
                                                    <li><Link to="#"><i className="fa-brands fa-linkedin"></i></Link></li>
                                                    <li><Link to="#"><i className="fa-brands fa-instagram"></i></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6">
                                    <div className="about-links-area">
                                        <h3>About Link</h3>
                                        <ul className="p-0">
                                            <li><Link to="blog1.html">Our Blog</Link></li>
                                            <li><Link to="about.html">About Us</Link></li>
                                            <li><Link to="service1.html">Practice Areas</Link></li>
                                            <li><Link to="testimonial1.html">Testimonials </Link></li>
                                            <li><Link to="contact1.html">Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                    <div className="get-links-area">
                                        <h3>Get In Touch</h3>
                                        <ul className="p-0">
                                            <li><img src="assets/img/icons/footer-email2.svg" alt=""/><Link to="maito:rikoisaac@amolatnpartners.com">rikoisaac@amolatnpartners.com</Link></li>
                                            <li><img src="assets/img/icons/footer-location1.svg" alt=""/><Link to="#">133 New Bridge Road,#14-01, Chinatown Point,<br/> Singapore 059413</Link></li>
                                                <li><img src="assets/img/icons/footer-phn.svg" alt=""/><Link to="tel:+6597838476">+6597838476</Link></li>
                                            </ul>
                                            </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="footer-contact-area">
                                                    <h3>Subscribe Our Newsletter</h3>
                                                    <div className="footer-form-area">
                                                        <form>
                                                            <input type="email" placeholder=" Email"/>
                                                                <div className="footer-btn">
                                                                    <button type="submit">Subscribe <i className="fa-regular fa-arrow-right"></i></button>
                                                                </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                    </div>
                                </div>
                                <div className="copyright-pera">
                                    <p>© Copyright 2024 Riko Isaac</p>
                                    <Link to="#">Privacy Plicy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </React.Fragment>
                )
}