import React from "react";
import { Link } from "react-router-dom";
import "./ServiceAbout.css";
import Quito3 from "../../Assets/quito3.png"
import countericon from "../../Assets/ServicesImages/court.png"


function ServiceAbout(){



    return(
        <React.Fragment>
        <section className="Service-about sp1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="topimg-box">
                            <img src="https://placehold.co/650x300" alt="" />
                        </div>
                        <div className="bottomimg-box topimg-box">
                        <img src="https://placehold.co/600x300" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div class="about5-content-area contentArea-padding">
                             <span className="sectionSub_title" data-aos="fade-up" data-aos-duration="600">PRACTICE</span>
                             <h2 className="section_title" data-aos="fade-up" data-aos-duration="800">AREAS OF LEGAL PRACTICE<img className="title-img" src={Quito3} alt=""/></h2>
                             <p className="common-para">
                             As a criminal defence lawyer in Singapore, I offer legal representation for individuals facing charges such as drug offences, white-collar crimes, and violent crimes. Understanding the challenges criminal cases bring, I am dedicated to protecting my clients' rights throughout the legal process.
                             </p>
                         
                         <div className="counter-box">
                            <div className="counter-one">
                                <div className="counter-icon-box">
                                    <img src={countericon} alt="" />
                                </div>
                                <div className="counter-text">
                                   <h2 class="count"><span>245</span> <span class="suffix">+</span></h2>
                                   <p>Global total Platform</p>
                                </div>
                            </div>
                            <div className="counter-one">
                                <div className="counter-icon-box">
                                    <img src={countericon} alt="" />
                                </div>
                                <div className="counter-text">
                                   <h2 class="count"><span>451 </span> <span class="suffix">+</span></h2>
                                   <p>Total Case Solved</p>
                                </div>
                            </div>
                            <div className="counter-one">
                                <div className="counter-icon-box">
                                    <img src={countericon} alt="" />
                                </div>
                                <div className="counter-text">
                                   <h2 class="count"><span>552</span> <span class="suffix">+</span></h2>
                                   <p>Global Award win</p>
                                </div>
                            </div>
                            
                         </div>
                         <div className="div" data-aos="fade-left" data-aos-duration="1400">
                            <Link to="about.html" className="welcome5-btn">Speak with a Lawyer
                            <svg class="svg-inline--fa fa-arrow-right" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z"></path></svg> 
                            {/* <i className="fa-regular fa-arrow-right"></i> */}
                            </Link>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </React.Fragment>
    )
}
export default ServiceAbout