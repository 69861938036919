import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Preloader from "./Components/Preloader";
import AOS from 'aos';
import 'aos/dist/aos.css'
import Home from "./Pages/Home";
import Service from "./Pages/Home/Service";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  if(loading) {
    return <Preloader />;
  }
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Service" element={<Service />} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;