import React from "react";
import { Link } from "react-router-dom";
import "./home.css";
import Header from "../../Components/Header"
import AboutUs from "../../Components/AboutUs/index.jsx"
import Services from "../../Components/Services/index.jsx"
import Work from "../../Components/Work-section/index.jsx"
import CaseStudies from "../../Components/CaseStudy/index.jsx"
import Testimonial from "../../Components/Testimonial/index.jsx"
import Blog from "../../Components/Blogs/index.jsx"
import HomeCTA from "../../Components/HomeCTA/index.jsx"
import Footer from "../../Components/Footer"
import Quito3 from "../../Assets/quito3.png"
import Elementor29 from "../../Assets/elementor29.png"
import Elementor28 from "../../Assets/elementor28.png"


export default function Home(){
    return(
        <React.Fragment>
            <Header/>
            <div className="welcome5-scetion-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="welcome5-textarea">
                                <span data-aos="fade-up" data-aos-duration="600">Law Professionals in Singapore</span>
                                <h1 data-aos="fade-up" data-aos-duration="800">Singapore’s Experts in Criminal & Family Law<img src={Quito3} alt=""/></h1>
                                <p data-aos="fade-up" data-aos-duration="1000">Riko Isaac is a dedicated and accomplished lawyer based in Singapore, specializing in criminal law, commercial litigation, and family law.</p>
                                <div data-aos="fade-up" data-aos-duration="1000">
                                <Link to="contact1.html" className="welcome5-btn" >Begin A Transformation
                                <svg class="svg-inline--fa fa-arrow-right" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z"></path></svg> 
                                </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="welcome5-elementors-area">
                                <div className="elementors-28">
                                    <img src={Elementor29} alt="" className="aniamtion-key-4" />
                                </div>
                                <div className="elementors-29">
                                    <img src={Elementor28} alt="" className="aniamtion-key-1"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="welcome5-images-area">
                                <div className="welcome-5-img" data-aos="zoom-in" data-aos-duration="1000">
                                    <img src="https://placehold.co/520x340" alt=""/>
                                </div>
                                <div className="welcome5-content-area" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="welcome5-images">
                                                <img src="https://placehold.co/202x161" alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="welcome5-images">
                                                <img src="https://placehold.co/202x161" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="content5-counter-area" data-aos="fade-left" data-aos-duration="1200">
                                <div className="content-counter text-center">
                                    <h3><span className="counter">546</span>+</h3>
                                    <p>Successful Cases</p>
                                </div>
                                <div className="content-counter text-center">
                                    <h3><span className="counter">550</span>+</h3>
                                    <p>Total Divorce Cases</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AboutUs/>
            <Services/>
            <Work/>
            <CaseStudies/>
            <Testimonial/>
            <Blog/>
            <HomeCTA/>
            <Footer/>
        </React.Fragment>
    )
}