import React from "react";
import { Link } from "react-router-dom";
import Quito5 from "../../Assets/home-page-images/quito5.svg"
import Quito3 from "../../Assets/quito3.png";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6"
import Slider from 'react-slick'; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
import {SliderData} from "./TestimonialData"

export default function Services() {
    const NextArrow = ({ onClick }) => {
        return (
            <button type="button" className="sliderarrow nextslider" onClick={onClick}>
                <FaArrowRightLong style={{ fontSize: '24px' }} />
            </button>
        );
    };
    const PrevArrow = ({ onClick }) => {
        return (
            <button type="button" className="sliderarrow prevslider" onClick={onClick}>
                <FaArrowLeftLong style={{ fontSize: '24px' }} />
            </button>
        );
    };
    const serviceslider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerPadding: "20px",
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      };

    return (
        <React.Fragment>
            <div className="testimonial6-section-area sp1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="service5-header">
                                <span className="sectionSub_title" data-aos="fade-up" data-aos-duration="600">Testimonial</span>
                                <h2 className="section_title" data-aos="fade-up" data-aos-duration="800">Real Stories, Real Results <img src={Quito3} alt=""/></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-lg-12 aos-init aos-animate" data-aos="fade-down" data-aos-duration="1000">
                            <div className="servicesliders">
                                <Slider {...serviceslider}>
                                    {SliderData.map((servicdata) => (
                                        <div className="item">
                                            <div class="testimonial6-box">
                                                <ul>
                                                    <li><Link to=""><i class="fa-solid fa-star"></i></Link></li>
                                                    <li><Link to=""><i class="fa-solid fa-star"></i></Link></li>
                                                    <li><Link to=""><i class="fa-solid fa-star"></i></Link></li>
                                                    <li><Link to=""><i class="fa-solid fa-star"></i></Link></li>
                                                    <li><Link to=""><i class="fa-solid fa-star"></i></Link></li>
                                                </ul>
                                                   <p>"I can't thank Your Law Firm Name enough for their unwavering a support during my divorce. Their team of lawyers guided me with compassion and expertise, making a challenging process feel law manageable. I highly recommend their services to & lawyer.”</p>
                                                <div class="testimonial6-auito-flex">
                                                    <div class="tesimonial6-man-info">
                                                        <div class="man-6img">
                                                          <img src="https://placehold.co/60x60" alt="" />
                                                        </div>
                                                        <div class="testimonial6-text">
                                                           <Link to="team1.html">Ben Stokes</Link>
                                                           <p>10 October 2024</p>
                                                        </div>
                                                    </div>
                                                    <img src={Quito5} alt="" class="quito-img1" />
                                                </div>
                                            </div>
                                        </div> 
                                        
                                    ))}       
                                </Slider>
                            </div>
                        </div>
                    </div>
             
                  
                   
                </div>
            </div>
        </React.Fragment>
    )
}