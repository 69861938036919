import React from "react";
import { Link } from "react-router-dom";
import ServiceIcon from "..//../Assets/ServicesImages/service5-h1.svg"
import Quito3 from "../../Assets/quito3.png";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6"
import Slider from 'react-slick'; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Services.css";
import {SliderData} from "./servicesdata"

export default function Services() {
    const NextArrow = ({ onClick }) => {
        return (
            <button type="button" className="sliderarrow nextslider" onClick={onClick}>
                <FaArrowRightLong style={{ fontSize: '24px' }} />
            </button>
        );
    };
    const PrevArrow = ({ onClick }) => {
        return (
            <button type="button" className="sliderarrow prevslider" onClick={onClick}>
                <FaArrowLeftLong style={{ fontSize: '24px' }} />
            </button>
        );
    };
    const serviceslider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerPadding: "20px",
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      };

    return (
        <React.Fragment>
            <div className="service5-section-area sp1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="service5-header">
                                <span className="sectionSub_title" data-aos="fade-up" data-aos-duration="600">Our Practice Areas</span>
                                <h2 className="section_title" data-aos="fade-up" data-aos-duration="800">Experienced Divorce <img src={Quito3} alt=""/> Lawyers by Your Side</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-lg-12 aos-init aos-animate" data-aos="fade-down" data-aos-duration="1000">
                            <div className="servicesliders">
                                <Slider {...serviceslider}>
                                    {SliderData.map((servicdata) => (
                                        <div className="item">
                                            <div class="service5-boxarea">
                                                <div class="service5-images">
                                                    <img src="https://placehold.co/412x412" alt="" />
                                                </div>
                                                <div class="service5-content-area">
                                                    <img src={ServiceIcon} alt="" class="home-icon" />
                                                    <Link to={servicdata.service_link}>{servicdata.service_heading}</Link>
                                                </div>
                                                <div class="service-content">
                                                    <img src={ServiceIcon} alt="" class="home-icon" />
                                                    <Link to={servicdata.service_link}>{servicdata.service_heading}</Link>
                                                    <p>{servicdata.service_content}</p>
                                                    <Link to={servicdata.service_link} class="learnmore">Read More <i class="fa-regular fa-arrow-right"></i></Link>
                                                </div>
                                            </div>
                                        </div> 
                                    ))}       
                                </Slider>
                            </div>
                        </div>
                    </div>
             
                  
                   
                </div>
            </div>
        </React.Fragment>
    )
}