import React from "react";
import { Link } from "react-router-dom";
import aboutImgMini from "../../Assets/home-page-images/about5-img4.png";
import liIcon from "../../Assets/home-page-images/check-img4.svg";
import Quito3 from "../../Assets/quito3.png";

import "./About-us.css";


export default function Footer() {
    return (
    <React.Fragment>
    <div className="about5-sction-area sp1">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="about5-images-area">
                        <div className="row">
                            <div className="col-lg-6" data-aos="fade-up" data-aos-duration="800">
                                <div className="about5-img1">
                                    <img src="https://placehold.co/306x331" alt=""/>
                                    <img src={Quito3} alt="" className="quito5"/>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                                <div className="about5-img1">
                                    <div className="space50"></div>
                                    <img src="https://placehold.co/306x304" alt=""/>

                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
                                <div className="about5-img1 about5-textarea">
                                    <h3><span className="counter">25</span>+</h3>
                                    <p>Years Of Experiance</p>
                                    <img className="aboutImgMini" src={aboutImgMini} alt=""/>
                                    <p>Divorce Satisfied Clients</p>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up" data-aos-duration="1400">
                                <div className="space30"></div>
                                <div className="about5-img1">
                                    <img src="https://placehold.co/306x280" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="about5-content-area">
                        <span className="sectionSub_title" data-aos="fade-left" data-aos-duration="600">About Us</span>
                        <h2 className="text-capitalize section_title" data-aos="fade-left" data-aos-duration="800">Riko Isaac: Proven<img src={Quito3} alt=""/>   Expertise in Singapore Law </h2>
                        <p data-aos="fade-left" data-aos-duration="1000">Our experienced divorce lawyers are not just legal professionals; they are your advocates, your confidants, and your partners Law</p>
                        <h3 data-aos="fade-left" data-aos-duration="1000">Our Best Service Benefits :</h3>
                        <div className="about5-lists" data-aos="fade-left" data-aos-duration="1200">
                            <ul>
                                <li><Link to=""><img className="li-icon" src={liIcon} alt=""/>Legal Expertise</Link></li>
                                <li><Link to=""><img className="li-icon" src={liIcon} alt=""/>Reduced Stress</Link></li>
                            </ul>
                            <ul>
                               <li> <Link to=""><img className="li-icon" src={liIcon} alt=""/>Paperwork & Documentation</Link></li>
                              <li><Link to=""><img className="li-icon" src={liIcon} alt=""/>Child Custody And Support</Link></li>
                            </ul>
                        </div>
                        <div className="div" data-aos="fade-left" data-aos-duration="1400">
                            <Link to="about.html" className="welcome5-btn">Speak with a Lawyer
                            <svg class="svg-inline--fa fa-arrow-right" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z"></path></svg> 
                            {/* <i className="fa-regular fa-arrow-right"></i> */}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
    </React.Fragment>
        )
}