import React from "react";
import { Link } from "react-router-dom";
import "./ServiceCTA.css";
import Quito3 from "../../Assets/quito3.png"


function ServiceAbout(){



    return(
        <React.Fragment>
        <section className="ServiceCTA sp1">
            <div className="container">
                <div className="row">
                   <div className="col-lg-12 text-center">
                        <div class="cta5-header-area">
                           <h2 className="section_title" data-aos="fade-up" data-aos-duration="800">Contact US Now<img className="title-img" src={Quito3} alt=""/> <br/></h2>
                        </div>
                        <div className="serviceCTA-btton">
                            <div className="div" data-aos="fade-left" data-aos-duration="1400">
                                <Link to="about.html" className="welcome5-btn">Explore More
                                <svg class="svg-inline--fa fa-arrow-right" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z"></path></svg> 
                                {/* <i className="fa-regular fa-arrow-right"></i> */}
                                </Link>
                            </div>
                            <div className="div" data-aos="fade-left" data-aos-duration="1400">
                                <Link to="about.html" className="welcome5-btn service-cta-2">Contact Us
                                <svg class="svg-inline--fa fa-arrow-right" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z"></path></svg> 
                                {/* <i className="fa-regular fa-arrow-right"></i> */}
                                </Link>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
        </section>
        </React.Fragment>
    )
}
export default ServiceAbout